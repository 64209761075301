import React from 'react';
import Nav from 'react-bootstrap/Nav';

function ZenDesk() {
    return (
        <Nav.Item>
            <Nav.Link href="https://help.vidsigner.net/s/?language=en_US" target="_blank" className="no-border-bottom"><i className="fa fa-2x fa-lg fa-question-circle" /></Nav.Link>
        </Nav.Item>
    );
}

export default ZenDesk;

