import axios from 'axios';
import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next';

class CreateUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: "",
                email: "",
                type: "1",
                identification: "",
                phone: "+34666666666",
                address: "Direction1",
                country: "es",
                city: "City1",
                state: "State1",
                zip: "0000",
                signatureImage: "",
                certificateId: "",
                p12: "",
                certalias: "",
                pin: "1111",
                dynamicpin: false,
                certtype: "KeyVault"
            },
            customers: [],
            customer: {
                name: "",
                id: "",
                legalId: "12345678A"
            },
            subscription: {
                name: "",
                domain: "",
                id: "",
                password: ""
            },
            stamper: {
                id: "",
                pin: "1111",
                description: "",
                certificateFile: "",
                imageFile: ""
            },
            isLoading: false,
            errorMsg: null,
            oauthToken: "",
            stamperOauthToken: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
        this.handleCustomerInputChange = this.handleCustomerInputChange.bind(this);
        this.handleClearName = this.handleClearName.bind(this);
        this.createUser = this.createUser.bind(this);
        this.getOauthToken = this.getOauthToken.bind(this);
        this.createSubscriptionForCustomer = this.createSubscriptionForCustomer.bind(this);
        this.handleImageFileSelection = this.handleImageFileSelection.bind(this);
        this.handleStamperCertificateFileSelection = this.handleStamperCertificateFileSelection.bind(this);
        this.handleVidMobilUserCertificateFileSelection = this.handleVidMobilUserCertificateFileSelection.bind(this);
        this.convertVidMobilUserSignatureImgToBase64 = this.convertVidMobilUserSignatureImgToBase64.bind(this);
    }

    componentDidMount() {
        var _this = this;

        _this.getOauthToken()
            .then(function () {
                _this.getCustomers()
                    .then(function (customers) {
                        customers.sort((a, b) => (a.customerName > b.customerName) ? 1 : ((b.customerName > a.customerName) ? -1 : 0));
                        _this.setState({ customers: customers });
                    })
                    .catch(function (error) { console.log(error.response); });
            })
            .catch(function (error) { console.log(error.response); });
            

        _this.convertVidMobilUserSignatureImgToBase64("/img/signature.png");
    }

    getOauthToken() {
        var _this = this;
        return new Promise(function (resolve, reject) {
            axios({
                method: 'GET',
                url: 'api/oauth/token',
                async: true,
                dataType: "json",
                contentType: 'application/json'
            })
                .then(function (response) {
                    _this.setState({ oauthToken: response.data });
                    resolve();
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    createCustomer() {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.state.customer.id !== "") {
                var selectedCustomer = _this.state.customers.find(x => x.customerGUID === _this.state.customer.id);
                resolve(selectedCustomer);
            } else {
                axios({
                    method: 'POST',
                    url: 'api/customer',
                    data: {
                        CustomerURL: "n/a",
                        CustomerName: _this.state.customer.name,
                        CustomerLegalId: _this.state.user.identification,
                        Address: _this.state.user.address,
                        City: _this.state.user.city,
                        State: _this.state.user.state,
                        PostalCode: _this.state.user.zip,
                        Country: _this.state.user.country,
                        CustomerEmail: _this.state.user.email,
                        CustomerPhoneNumber: _this.state.user.phone
                    },
                    async: true,
                    dataType: "json",
                    contentType: 'application/json'
                })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error.response.data);
                    });
            }
        });
    }

    createSubscriptionForCustomer(customerId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            axios({
                method: 'POST',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/customers/' + customerId + '/subscriptions',
                data: {
                    SubscriptionName: _this.state.subscription.name,
                    SignatureChannels: {
                        BIO: {
                            Enabled: true,
                            SignaturesLimit: -1,
                            SignaturesLimitPeriod: 1,
                            DevicesLimit: 3
                        },
                        Remote: {
                            Enabled: true,
                            SignaturesLimit: -1,
                            SignaturesLimitPeriod: 1
                        },
                        ViDMobile: {
                            Enabled: true,
                            Domain: _this.state.subscription.domain,
                            SignaturesLimit: -1,
                            SignaturesLimitPeriod: 1,
                            UsersLimit: -1
                        },
                        Stamper: {
                            Enabled: true,
                            SignaturesLimit: -1,
                            SignaturesLimitPeriod: 1,
                            CertificatesLimit: -1
                        }
                    }
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Bearer ' + _this.state.oauthToken }
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    subscriptionActivation(subscriptionId) {
        var _this = this;

        axios({
            method: 'PUT',
            url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/subscription/' + subscriptionId + '/status/active',
            async: true,
            dataType: "json",
            contentType: 'application/json',
            headers: { 'Authorization': 'Bearer ' + _this.state.oauthToken }
        })
            .then(function (response) { })
            .catch(function (error) { console.log(error); });
    }

    createEnrollmentCode(subscriptionId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            axios({
                method: 'POST',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/subscription/' + subscriptionId + '/enrollmentcode',
                data: {
                    NumberOfDevices: 3
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Bearer ' + _this.state.oauthToken }
            })
                .then(function (response) {
                    resolve(response.data.GUID);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    createVidMobileUser() {
        var _this = this;

        return new Promise(function (resolve, reject) {

            var currentSubscription = _this.state.subscription;
            var authorizationHeader = btoa(currentSubscription.name + ':' + currentSubscription.password);

            var currentUser = _this.state.user;
            axios({
                method: 'POST',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/vidmobileusers',
                data: {
                    Userid: currentUser.email,
                    Username: currentUser.name,
                    Legalid: _this.state.customer.legalId,
                    Email: currentUser.email,
                    Password: "Demo1234#",
                    SignatureImage: currentUser.signatureImage,
                    SkipEnrollEmail: true,
                    PhoneNumber: currentUser.phone,
                    Language: currentUser.country
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Basic ' + authorizationHeader }
            })
                .then(function (response) {
                    resolve(response.data.ViDMobileUserGUID);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    getStamperOauthToken() {
        var _this = this;
        return new Promise(function (resolve, reject) {
            
            axios({
                method: 'GET',
                url: 'api/oauth/token',
                params: {
                    username: _this.state.subscription.name,
                    password: _this.state.subscription.password,
                    scope: "subscription"
                },
                async: true,
                dataType: "json",
                contentType: 'application/json'
            })
                .then(function (response) {
                    _this.setState({ stamperOauthToken: response.data });
                    resolve();
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    convertVidMobilUserSignatureImgToBase64(url) {
        var _this = this;

        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            canvas.height = this.height;
            canvas.width = this.width;
            ctx.drawImage(this, 0, 0);
            var b64SignatureImage = canvas.toDataURL('image/png');
            b64SignatureImage = b64SignatureImage.substring((b64SignatureImage.indexOf(',') + 1));
            _this.setState(prevState => ({
                user: {
                    ...prevState.user,
                    signatureImage: b64SignatureImage
                }
            }));
            canvas = null;
        };
        img.src = url;
    }

    handleImageFileSelection(e) {
        var _this = this;
        var imageFile = e.target.files[0];

        if (typeof imageFile !== "undefined" && imageFile !== "") {
            var reader = new FileReader();

            reader.onload = function () {
                var b64SignatureImage = reader.result;
                b64SignatureImage = b64SignatureImage.substring((b64SignatureImage.indexOf(',') + 1));
                _this.setState(prevState => ({
                    stamper: {
                        ...prevState.stamper,
                        imageFile: b64SignatureImage
                    }
                }));
            };

            reader.readAsDataURL(imageFile);
        }
    }

    handleVidMobilUserCertificateFileSelection(e) {
        var _this = this;

        this.handleCertificateFileSelectionHelper(e, function (b64NewCertificate) {
            _this.setState(prevState => ({
                user: {
                    ...prevState.user,
                    p12: b64NewCertificate
                }
            }));
        });
    }

    handleStamperCertificateFileSelection(e) {
        var _this = this;

        this.handleCertificateFileSelectionHelper(e, function (b64NewCertificate) {
            _this.setState(prevState => ({
                stamper: {
                    ...prevState.stamper,
                    certificateFile: b64NewCertificate
                }
            }));
        });
    }

    handleCertificateFileSelectionHelper(e, callback) {

        var certificateFile = e.target.files[0];
        var filename = certificateFile.name;
        var regExpWhiteSpace = new RegExp(" ", "g");
        filename = filename.replace(regExpWhiteSpace, "_");
        var indexExt = filename.lastIndexOf(".");
        var ext = "";

        if (indexExt > 0) {
            ext = filename.substring(indexExt + 1);
        }

        var b64NewCertificate = null;

        if (ext.toUpperCase() === "P12" || ext.toUpperCase() === "PFX") {
            var reader = new FileReader();
            reader.onload = function () {
                b64NewCertificate = reader.result;
                //Rid of "data:application/x-pkcs12;base64"
                b64NewCertificate = b64NewCertificate.substring((b64NewCertificate.indexOf(',') + 1));
                callback(b64NewCertificate);
            };
            reader.readAsDataURL(certificateFile);
        }
    }

    createVidMobilUserCertificate(vidMobilUserLegalId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var currentSubscription = _this.state.subscription;
            var authorizationHeader = btoa(currentSubscription.name + ':' + currentSubscription.password);

            var user = _this.state.user;
            axios({
                method: 'POST',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/vidmobileusers/' + vidMobilUserLegalId + '/certs',
                data: {
                    p12: user.p12,
                    certalias: user.certalias,
                    pin: user.pin,
                    dynamicpin: user.dynamicpin,
                    certtype: user.certtype
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Basic ' + authorizationHeader }
            })
                .then(function (response) {
                    resolve(response.data.GUID);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    createStamper(subscriptionId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var stamper = _this.state.stamper;
            axios({
                method: 'POST',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/subscription/' + subscriptionId + '/stampcertificates',
                data: {
                    pin: stamper.pin,
                    Description: stamper.description,
                    Image: stamper.imageFile,
                    CertificateBase64: stamper.certificateFile
                },
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Bearer ' + _this.state.stamperOauthToken }
            })
                .then(function (response) {
                    resolve(response.data.CertificateGUID);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    getCustomers() {
        return new Promise(function (resolve, reject) {
            axios({
                method: 'GET',
                url: '/api/customer/all',
                async: true,
                dataType: "json",
                contentType: 'application/json'
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    createDevSiteUser(customerId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var newUser = _this.state.user;

            axios({
                method: 'POST',
                url: 'api/User',
                data: newUser,
                params: {
                    customerId: customerId
                },
                contentType: 'application/json'
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === 500) {
                        error.response.data = "Error. The email you entered already exists in our database";
                    }
                    reject(error.response.data);
                });
        });
    }

    createDevSiteAdmin() {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var newUser = _this.state.user;

            axios({
                method: 'POST',
                url: 'api/User/CreateAdmin',
                data: newUser,
                contentType: 'application/json'
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === 500) {
                        error.response.data = "Error. The email you entered already exists in our database";
                    }
                    reject(error.response.data);
                });
        });
    }

    deleteCustomer(customerId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var isNew = _this.state.customers.find(x => x.id === customerId) === undefined ? true : false;

            if (isNew) {
                axios({
                    method: 'DELETE',
                    url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/customers/' + customerId,
                    async: true,
                    dataType: "json",
                    contentType: 'application/json',
                    headers: { 'Authorization': 'Bearer ' + _this.state.oauthToken }
                })
                    .then(function (response) {
                        _this.setState(prevState => ({
                            customer: {
                                ...prevState.customer,
                                id: ""
                            }
                        }));
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error.response.data);
                    });
            } else {
                resolve();
            }
        });

    }

    deleteSubscription(subscriptionId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getEnrollmentCodesForSubscription(subscriptionId)
                .then(function (enrollmentCodes) {
                    enrollmentCodes.forEach(function (enrollment) {
                        _this.deleteEnrollmentCodeById(enrollment.GUID)
                            .then(function () {
                                console.log("EnrollmentCode " + enrollment.GUID + " deleted!");
                                enrollmentCodes = enrollmentCodes.filter(x => x.GUID !== enrollment.GUID);

                                if (enrollmentCodes.length === 0) {
                                    _this.deleteSubscriptionHelper(subscriptionId)
                                        .then(function () {
                                            console.log("Subscription " + subscriptionId + " deleted!");
                                            resolve();
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                            reject(error);
                                        });
                                }

                            })
                            .catch(function (error) {
                                console.log(error);
                                reject(error);
                            });
                    });
                })
                .catch(function (error) {
                    console.log(error.response);
                    /*Error 404: The subscription does not have enrollment codes so delete subscription*/
                    if (error.response.status === 404) {
                        _this.deleteSubscriptionHelper(subscriptionId)
                            .then(function () {
                                console.log("Subscription " + subscriptionId + " deleted!");
                                resolve();
                            })
                            .catch(function (error) {
                                console.log(error.response);
                                reject();
                            });
                    }
                });
        });
    }

    deleteSubscriptionHelper(subscriptionId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            axios({
                method: 'DELETE',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/subscription/' + subscriptionId,
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Bearer ' + _this.state.oauthToken }
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    getEnrollmentCodesForSubscription(subscriptionId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            axios({
                method: 'GET',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/subscription/' + subscriptionId + '/enrollmentcode',
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Bearer ' + _this.state.oauthToken }
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    deleteEnrollmentCodeById(enrollmentCodeId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            axios({
                method: 'DELETE',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/enrollmentcodes/' + enrollmentCodeId,
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Bearer ' + _this.state.oauthToken }
            })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    deleteVidMobilUser(userLegalId) {
        var _this = this;

        return new Promise(function (resolve, reject) {
            var subscription = _this.state.subscription;
            var authorizationHeader = btoa(subscription.name + ':' + subscription.password);

            axios({
                method: 'DELETE',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/vidmobileusers/' + userLegalId,
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Basic ' + authorizationHeader }
            })
                .then(function (response) {
                    resolve(response.status);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    deleteVIDmobiluserCertificate(userLegalId) {
        var _this = this;
        var subscription = _this.state.subscription;
        var authorizationHeader = btoa(subscription.name + ':' + subscription.password);

        return new Promise(function (resolve, reject) {

            axios({
                method: 'GET',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/vidmobileusers/' + userLegalId + '/certs',
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Basic ' + authorizationHeader }
            })
                .then(function (response) {
                    _this.state.user.certificateId = response.data[0].GUID;

                    axios({
                        method: 'DELETE',
                        url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/vidmobileusers/' + userLegalId + '/certs/' + _this.state.user.certificateId,
                        async: true,
                        dataType: "json",
                        contentType: 'application/json',
                        headers: { 'Authorization': 'Basic ' + authorizationHeader }
                    })
                        .then(function (response) {
                            resolve(response.status);
                        })
                        .catch(function (error) {
                            reject(error.response.data);
                        });
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    deleteStamperCertificate() {
        var _this = this;

        return new Promise(function (resolve, reject) {
            axios({
                method: 'DELETE',
                url: 'https://pre-vidsignercloudmgmt.validatedid.com/api/subscription/' + _this.state.subscription.id + '/stampcertificates/' + _this.state.stamper.id,
                async: true,
                dataType: "json",
                contentType: 'application/json',
                headers: { 'Authorization': 'Bearer ' + _this.state.stamperOauthToken }
            })
                .then(function (response) {
                    resolve(response.status);
                })
                .catch(function (error) {
                    reject(error.response.data);
                });
        });
    }

    isAnExistingDevSiteUser(email) {
        return new Promise(function (resolve, reject) {
            axios({
                method: 'GET',
                url: 'api/User/GetUserByEmail',
                params: { targetEmail: email },
                headers: { 'Content-Type': 'application/json' }
            })
                .then(function () {
                    resolve();
                })
                .catch(function () {
                    reject();
                });
        });
    }

    /**
     * If vidMobilUser is not empty -> delete vidMobilUser, subscription and customer
     * Else if subscriptionId is defined -> delete subscription and customer
     * Else delete customer
     * @param {any} value Is an object like { vidMobilUserLegalId: null,subscriptionId: null, customerId: null}
     */
    rollbackCreationUserProccess(value) {
        var _this = this;

        if (value.stamperId !== null) {

            _this.deleteStamperCertificate()
                .then(function () {
                    _this.deleteVIDmobiluserCertificate(_this.state.customer.legalId)
                        .then(function () {
                            _this.deleteVidMobilUser(value.vidMobilUserLegalId)
                                .then(function () {
                                    _this.deleteSubscription(value.subscriptionId)
                                        .then(function () {
                                            _this.deleteCustomer(value.customerId)
                                                .then(function () {
                                                    console.log("Deleted StamperCertificate,VidMobileUserCertificate,VidMobilUser,Subscription,Customer!");
                                                })
                                                .catch(function (error) { console.log(error); });
                                        })
                                        .catch(function (error) { console.log(error); });
                                })
                                .catch(function (error) { console.log(error); });
                        })
                        .catch(function (error) { console.log(error); });
                })
                .catch(function (error) { console.log(error); });

        }
        else if (value.vidMobilUserCertificateId !== null) {

            _this.deleteVIDmobiluserCertificate(_this.state.customer.legalId)
                .then(function () {
                    _this.deleteVidMobilUser(value.vidMobilUserLegalId)
                        .then(function () {
                            _this.deleteSubscription(value.subscriptionId)
                                .then(function () {
                                    _this.deleteCustomer(value.customerId)
                                        .then(function () {
                                            console.log("Deleted VidmMobileUserCertificate,VidMobilUser,Subscription,Customer!");
                                        })
                                        .catch(function (error) { console.log(error); });
                                })
                                .catch(function (error) { console.log(error); });
                        })
                        .catch(function (error) { console.log(error); });
                })
                .catch(function (error) { console.log(error); });
        }
        else if (value.vidMobilUserLegalId !== null) {

            _this.deleteVidMobilUser(value.vidMobilUserLegalId)
                .then(function () {
                    _this.deleteSubscription(value.subscriptionId)
                        .then(function () {
                            _this.deleteCustomer(value.customerId)
                                .then(function () {
                                    console.log("Deleted VidMobilUser,Subscription,Customer!");
                                })
                                .catch(function (error) { console.log(error); });
                        })
                        .catch(function (error) { console.log(error); });
                })
                .catch(function (error) { console.log(error); });


        } else if (value.subscriptionId !== null) {

            _this.deleteSubscription(value.subscriptionId)
                .then(function () {
                    _this.deleteCustomer(value.customerId)
                        .then(function () {
                            console.log("Deleted Subscription,Customer!");
                        })
                        .catch(function (error) { console.log(error); });
                })
                .catch(function (error) { console.log(error); });
        } else if (value.customerId !== null) {

            _this.deleteCustomer(value.customerId)
                .then(function () {
                    console.log("Deleted CustomerId: " + value.customerId);
                })
                .catch(function (error) { console.log(error); });
        } else { console.log("Error. Anything was deleted! "); }
    }

    /**
     * Generic handle input. The input has the name and destination attributes defined in it. 
     * ...prevState[_destination] see -> https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react
     * @param {any} event is the current input control
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const _destination = target.attributes.destination.value;

        this.setState(prevState => ({
            [_destination]: {
                ...prevState[_destination],
                [name]: value
            }
        }));
    }

    handleEmailInputChange(event) {
        const target = event.target;
        var value = target.value;

        value = value.trim();

        this.setState(prevState => ({
            user: {
                ...prevState.user,
                email: value
            }
        }));
    }

    handleCustomerInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "name") {
            var noBlankValue = value.split(' ').join('');

            this.setState(prevState => ({
                customer: {
                    ...prevState.customer,
                    name: value,
                    id: ""
                },
                user: {
                    ...prevState.user,
                    identification: "NIF" + noBlankValue,
                    certalias: noBlankValue + "Cert"
                },
                subscription: {
                    ...prevState.subscription,
                    name: noBlankValue + "SubscriptionDemo",
                    domain: noBlankValue + "Domain"
                },
                stamper: {
                    ...prevState.stamper,
                    description: noBlankValue + "Stamper"
                }
            }));
        } else {
            this.setState(prevState => ({
                customer: {
                    ...prevState.customer,
                    name: "",
                    id: value
                }
            }));
        }
    }

    handleClearName() {
        this.setState(prevState => ({
            customer: {
                ...prevState.customer,
                name: ""
            }
        }));
    }

    createUser(e) {
        e.preventDefault();

        var _this = this;

        if (_this.state.user.type === "1") {
            _this.setState({ isLoading: true, errorMsg: null });

            if (_this.state.customer.id !== "") {
                _this.createDevSiteUser(_this.state.customer.id)
                    .then(function (userId) {
                        _this.setState(prevState => ({
                            user: {
                                ...prevState.user,
                                id: userId
                            },
                            isLoading: false
                        }));

                        console.log("createDevSiteUser finished successfully...");
                        _this.props.submit(_this.state.user);
                    })
                    .catch(function (error) {
                        _this.setState({ isLoading: false, errorMsg: error });
                    });
            } else {
                _this.isAnExistingDevSiteUser(_this.state.user.email)
                    .then(function () {
                        _this.setState({ errorMsg: "Error. This email already exists in our database!", isLoading: false });
                    })
                    .catch(function () {
                        _this.createCustomer()
                            .then(function (customer) {
                                _this.setState(prevState => ({
                                    customer: {
                                        ...prevState.customer,
                                        id: customer.customerGUID,
                                        legalId: customer.customerLegalId
                                    }
                                }));
                                _this.createSubscriptionForCustomer(customer.customerGUID)
                                    .then(function (subscription) {

                                        _this.subscriptionActivation(subscription.SubscriptionGUID);

                                        _this.setState(prevState => ({
                                            subscription: {
                                                ...prevState.subscription,
                                                id: subscription.SubscriptionGUID,
                                                password: subscription.SubscriptionPass
                                            }
                                        }));

                                        _this.createEnrollmentCode(subscription.SubscriptionGUID)
                                            .then(function (enrollmentGuid) {
                                                _this.createVidMobileUser()
                                                    .then(function (ViDMobileUserGUID) {
                                                        _this.createVidMobilUserCertificate(_this.state.customer.legalId)
                                                            .then(function () {
                                                                _this.getStamperOauthToken()
                                                                    .then(function () {
                                                                        _this.createStamper(subscription.SubscriptionGUID)
                                                                            .then(function (stamperId) {
                                                                                _this.setState(prevState => ({
                                                                                    stamper: {
                                                                                        ...prevState.stamper,
                                                                                        id: stamperId
                                                                                    }
                                                                                }));

                                                                                _this.createDevSiteUser(customer.customerGUID)
                                                                                    .then(function (userId) {
                                                                                        _this.setState(prevState => ({
                                                                                            user: {
                                                                                                ...prevState.user,
                                                                                                id: userId
                                                                                            },
                                                                                            isLoading: false
                                                                                        }));
                                                                                        console.log("User creations finished successfully...");
                                                                                        _this.props.submit(_this.state.user);
                                                                                    })
                                                                                    .catch(function (error) {

                                                                                        _this.rollbackCreationUserProccess({
                                                                                            stamperId: _this.state.stamper.id,
                                                                                            vidMobilUserCertificateId: _this.state.user.certificateId,
                                                                                            vidMobilUserLegalId: _this.state.customer.legalId,
                                                                                            subscriptionId: subscription.SubscriptionGUID,
                                                                                            customerId: _this.state.customer.id
                                                                                        });
                                                                                        _this.setState({ errorMsg: error, isLoading: false });
                                                                                    });
                                                                            })
                                                                            .catch(function (error) {
                                                                                _this.rollbackCreationUserProccess({
                                                                                    stamperId: null,
                                                                                    vidMobilUserCertificateId: _this.state.user.certificateId,
                                                                                    vidMobilUserLegalId: _this.state.customer.legalId,
                                                                                    subscriptionId: subscription.SubscriptionGUID,
                                                                                    customerId: _this.state.customer.id
                                                                                });
                                                                                _this.setState({ errorMsg: error, isLoading: false });
                                                                            });
                                                                    })
                                                                    .catch(function (error) {

                                                                        _this.rollbackCreationUserProccess({
                                                                            stamperId: null,
                                                                            vidMobilUserCertificateId: _this.state.user.certificateId,
                                                                            vidMobilUserLegalId: _this.state.customer.legalId,
                                                                            subscriptionId: subscription.SubscriptionGUID,
                                                                            customerId: _this.state.customer.id
                                                                        });
                                                                        _this.setState({ errorMsg: error, isLoading: false });
                                                                    });
                                                            })
                                                            .catch(function (error) {
                                                                _this.rollbackCreationUserProccess({
                                                                    stamperId: null,
                                                                    vidMobilUserCertificateId: null,
                                                                    vidMobilUserLegalId: _this.state.customer.legalId,
                                                                    subscriptionId: subscription.SubscriptionGUID,
                                                                    customerId: _this.state.customer.id
                                                                });
                                                                _this.setState({ errorMsg: error, isLoading: false });
                                                            });
                                                    })
                                                    .catch(function (error) {
                                                        _this.rollbackCreationUserProccess({
                                                            stamperId: null,
                                                            vidMobilUserCertificateId: null,
                                                            vidMobilUserLegalId: null,
                                                            subscriptionId: subscription.SubscriptionGUID,
                                                            customerId: _this.state.customer.id
                                                        });
                                                        _this.setState({ errorMsg: error, isLoading: false });
                                                    });
                                            })
                                            .catch(function (error) {
                                                _this.rollbackCreationUserProccess({
                                                    stamperId: null,
                                                    vidMobilUserCertificateId: null,
                                                    vidMobilUserLegalId: null,
                                                    subscriptionId: subscription.SubscriptionGUID,
                                                    customerId: _this.state.customer.id
                                                });
                                                _this.setState({ errorMsg: error, isLoading: false });
                                            });
                                    })
                                    .catch(function (error) {
                                        _this.rollbackCreationUserProccess({
                                            stamperId: null,
                                            vidMobilUserCertificateId: null,
                                            vidMobilUserLegalId: null,
                                            subscriptionId: null,
                                            customerId: customer.customerGUID
                                        });
                                        _this.setState({ errorMsg: error, isLoading: false });
                                    });
                            })
                            .catch(function (error) { _this.setState({ errorMsg: error, isLoading: false }); });
                    });
            }
        } else {
            /*It's an Admin so just create a new devsite user with adn existing customerId*/
            _this.createDevSiteAdmin()
                .then(function (userId) {
                    _this.setState(prevState => ({
                        user: {
                            ...prevState.user,
                            id: userId
                        },
                        isLoading: false
                    }));

                    console.log("createDevSiteUser finished successfully...");
                    _this.props.submit(_this.state.user);
                })
                .catch(function (error) {
                    console.log(error);
                    _this.setState({ isLoading: false, errorMsg: error });
                });
        }
    }

    renderVidMobilUserCertificate() {
        const { user } = this.state;
        const { t } = this.props;

        return (
            <>
                <Form.Row>
                    <Col>
                        <Form.Label>{t('user.modalVIDmobiluserTitle')}</Form.Label>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} sm={6}>
                        <Form.Control type="text" name="pin"
                            value={user.pin}
                            destination="user"
                            placeholder={t('user.modalVIDmobiluserPinPlaceholder')}
                            onChange={this.handleInputChange} required
                        />
                    </Form.Group>

                    <Form.Group as={Col} sm={6}>
                        <Form.Control type="text" name="certalias"
                            value={user.certalias}
                            destination="user"
                            placeholder={t('user.modalVIDmobiluserAliasPlaceholder')}
                            onChange={this.handleInputChange} required
                        />
                    </Form.Group>

                    <Form.Group as={Col} sm={6}>
                        <Form.Label>{t('user.modalVIDmobiluserCertificate')}</Form.Label>
                        <Form.Control type='file'
                            accept=".p12,.pfx"
                            onChange={this.handleVidMobilUserCertificateFileSelection} required
                        />
                    </Form.Group>
                </Form.Row>
            </>
        );
    }

    render() {
        const { user, isLoading, errorMsg, customers, customer, subscription, stamper } = this.state;
        const { t, close } = this.props;

        const disabledCustomerName = customer.id !== "" ? {} : { display: 'none' };
        const disabledCustomerSelection = customer.name !== "" ? {} : { display: 'none' };

        return (
            <Modal show animation={false} centered size="lg" onHide={() => this.props.close()} backdrop="static">
                <Modal.Header>
                    <Modal.Title>{t('user.modalCreateTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        errorMsg !== null &&
                        <Alert variant={'danger'} className="mt-2">
                            {errorMsg}
                        </Alert>
                    }
                    {isLoading
                        ?
                        <p><em>{t('user.modalCreatingUser')}</em></p>
                        :
                        <Form id="createUserForm" onSubmit={this.createUser} >
                            <Form.Row>
                                <Form.Group as={Col} xs={12} sm={8}>
                                    <Form.Label>{t('user.modalName')}</Form.Label>
                                    <Form.Control type="text" name="name"
                                        value={user.name}
                                        destination="user"
                                        placeholder={t('user.modalNamePlaceholder')}
                                        onChange={this.handleInputChange} required
                                    />
                                </Form.Group>

                                <Form.Group as={Col} xs={12} sm={4}>
                                    <Form.Label>{t('user.modalType')}</Form.Label>
                                    <Form.Control as="select" name="type"
                                        destination="user"
                                        onChange={this.handleInputChange}
                                        defaultValue={user.type} required
                                    >
                                        <option key="0" value="0">Admin</option>
                                        <option key="1" value="1">User</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} xs={12}>
                                    <Form.Label>{t('user.modalEmail')} </Form.Label>
                                    <Form.Control type="email" name="email"
                                        value={user.email}
                                        destination="user"
                                        placeholder={t('user.modalEmailPlaceholder')}
                                        onChange={this.handleEmailInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Form.Row>

                            {user.type === "1" &&
                                <div>
                                    <hr />

                                    <Form.Row>
                                        <Form.Group as={Col} xs={12} sm={6} >
                                            <Form.Label>
                                                {t('user.modalNewCustomer')}
                                                <small style={disabledCustomerName}> {t('general.disabledLabel')}</small>
                                            </Form.Label>
                                            <InputGroup >
                                                <Form.Control id="newCustomerName" type="text" name="name"
                                                    placeholder={t('user.modalNewCustomerPlaceholder')}
                                                    value={customer.name}
                                                    onChange={this.handleCustomerInputChange}
                                                    disabled={customer.id !== ""}
                                                />
                                                <InputGroup.Append>
                                                    <Button variant="link" onClick={this.handleClearName}
                                                        disabled={customer.id !== ""}
                                                    >
                                                        {t('user.modalClearInput')}
                                                    </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                            <Form.Text className="text-muted">
                                                {t('user.modalCustomerHelper')}
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} sm={6}>
                                            <Form.Label>
                                                {t('user.modalExistingCustomers')}
                                                <small style={disabledCustomerSelection}>
                                                    {t('general.disabledLabel')}
                                                </small>
                                            </Form.Label>
                                            <Form.Control id="selectCustomer" as="select"
                                                value={customer.id}
                                                name="id"
                                                onChange={this.handleCustomerInputChange}
                                                disabled={customer.name !== ""}
                                            >
                                                <option value="">{t('user.modalNoSelection')}</option>
                                                {customers.map((customer) =>
                                                    <option key={customer.customerGUID} value={customer.customerGUID}>{customer.customerName}</option>
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>

                                    {customer.id === "" &&
                                        <div>
                                            <hr />

                                            <Form.Row>
                                                <Col>
                                                    <Form.Label>{t('user.modalDetailsTitle')}</Form.Label>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} sm={12}>
                                                    <Form.Control type="text" name="address"
                                                        value={user.address}
                                                        destination="user"
                                                        placeholder={t('user.modalAddressPlaceholder')}
                                                        onChange={this.handleInputChange} required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={4}>
                                                    <Form.Control as="select" name="country"
                                                        destination="user"
                                                        onChange={this.handleInputChange}
                                                        defaultValue={user.country} required
                                                    >
                                                        <option key="es" value="es">Spain</option>
                                                        <option key="en" value="en">England</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} sm={4}>
                                                    <Form.Control type="text" name="city"
                                                        value={user.city}
                                                        destination="user"
                                                        placeholder={t('user.modalCityPlaceholder')}
                                                        onChange={this.handleInputChange} required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={4}>
                                                    <Form.Control type="text" name="state"
                                                        value={user.state}
                                                        destination="user"
                                                        placeholder={t('user.modalProvincePlaceholder')}
                                                        onChange={this.handleInputChange} required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={4}>
                                                    <Form.Control type="text" name="zip"
                                                        value={user.zip}
                                                        destination="user"
                                                        placeholder={t('user.modalZipPlaceholder')}
                                                        onChange={this.handleInputChange} required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={4}>
                                                    <Form.Control type="text" name="identification"
                                                        value={user.identification}
                                                        destination="user"
                                                        placeholder={t('user.modalIdentificationPlaceholder')}
                                                        onChange={this.handleInputChange}
                                                        pattern="[0-9a-zA-Z_.-]*"
                                                        required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={4}>
                                                    <Form.Control type="text" name="phone"
                                                        value={user.phone}
                                                        destination="user"
                                                        placeholder={t('user.modalPhonePlaceholder')}
                                                        onChange={this.handleInputChange} required
                                                    />
                                                </Form.Group>
                                            </Form.Row>

                                            <hr />

                                            <Form.Row>
                                                <Col>
                                                    <Form.Label>{t('user.modalSubscription')}</Form.Label>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} sm={6}>
                                                    <Form.Control type="text" name="name"
                                                        value={subscription.name}
                                                        destination="subscription" minLength="8"
                                                        placeholder={t('user.modalSubscriptionNamePlaceholder')}
                                                        onChange={this.handleInputChange}
                                                        pattern="[0-9a-zA-Z_.-]*"
                                                        required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={6}>
                                                    <Form.Control type="text" name="domain"
                                                        value={subscription.domain}
                                                        destination="subscription"
                                                        placeholder={t('user.modalSubscriptionDomainPlaceholder')}
                                                        onChange={this.handleInputChange}
                                                        pattern="[0-9a-zA-Z_.-]*"
                                                        required
                                                    />
                                                </Form.Group>
                                            </Form.Row>

                                            <hr />

                                            {this.renderVidMobilUserCertificate()}

                                            <hr />

                                            <Form.Row>
                                                <Col>
                                                    <Form.Label>{t('user.modalStamperTitle')}</Form.Label>
                                                </Col>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} sm={6}>
                                                    <Form.Control type="text" name="pin"
                                                        value={stamper.pin}
                                                        destination="stamper"
                                                        placeholder={t('user.stamperPinPlaceholder')}
                                                        onChange={this.handleInputChange} required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={6}>
                                                    <Form.Control type="text" name="description"
                                                        value={stamper.description}
                                                        destination="stamper"
                                                        placeholder={t('user.stamperDescriptionPlaceholder')}
                                                        onChange={this.handleInputChange} required
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={6}>
                                                    <Form.Label>{t('user.stamperCertificateTitle')}</Form.Label>
                                                    <Form.Control type='file'
                                                        accept=".p12,.pfx"
                                                        onChange={this.handleStamperCertificateFileSelection} required
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm={6}>
                                                    <Form.Label>{t('user.stamperImageTitle')}</Form.Label>
                                                    <Form.Control type='file'
                                                        accept=".jpg,.png,.jpeg"
                                                        onChange={this.handleImageFileSelection} required
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </div>
                                    }
                                </div>
                            }
                        </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {!isLoading &&
                        <>
                            <Button className="grey-button" onClick={close}>{t('general.closeLabel')}</Button>
                            <Button className="green-button" form="createUserForm" type="submit">{t('general.saveLabel')}</Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(CreateUserModal);
