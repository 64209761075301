import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logout from './Logout';
import NavBarBrand from './NavBarBrand';
import NavDropdownLanguage from './NavDropdownLanguage';
import ZenDesk from './ZenDesk';

function NavBarAdmin() {
    return (
        <Navbar bg="light" variant="light" className="green-background" collapseOnSelect expand="sm">
            <NavBarBrand href="/admin" />
            <Navbar.Toggle />
            <Navbar.Collapse id="navbar-admin">
                <Nav className="ml-auto">
                    <NavDropdownLanguage />
                    <Logout />
                    <ZenDesk />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBarAdmin;
